/* eslint-disable max-len */
// Imports
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

// CSS
import * as IndexStyles from './css/Index.module.css';

// Components
import Layout from '../components/Layout';
import TwoColTextContent from '../components/TwoColTextContent';

const styles = {
	underline: {borderBottom: '1px solid black'},
	small: {fontSize: 15, verticalAlign: 18},
};

// ======== Component ========
const Index = () => {
	return (
		<Layout title={'Balanced Body UK'}>
			<div className={`flex w100 flex-ai-fs flex-jc-fs ${ IndexStyles.container }`}>

				<a href={'/sale'}>
					<StaticImage
						className={IndexStyles.image}
						src={'../assets/images/uk-home@2x.jpg'}
						alt={'People working out on Reformers'}
					/>
				</a>
				{/* <div className={IndexStyles.intro}>
					<p className={`body-text ${ IndexStyles.promotext }`}>We are bringing specially curated equipment to The Pilates Gathering  & it is for sale after at a discount*! Reserve in advance and pick-up onsite at the event.  Event attendance not required. BB brings the equipment to Stirling and you transport home – no extra shipping fees!!!</p>
					<p className={`body-text ${ IndexStyles.promotext }`}>
						Questions? Please use the chat tool,
						<a className={`link-text`} href={'mailto:info@pilates.com'}>email us</a>
						{' '}
						or call us at 08000148207. First come, first served and while supplies last only. Terms & Conditions apply**. Get yours today!
					</p>
					<p className={`body-text ${ IndexStyles.promotextitalics }`}>*VAT and processing fees calculated on discounted price and added at time of purchase.</p>
					<p className={`body-text ${ IndexStyles.promotextitalics }`}>**This sale for UK & Ireland only and not applicable in Australia, Austria, Belarus, Belgium, Costa Rica, Denmark, France, Germany, Greece, Hong Kong, India, Israel, Italy, Kazakhstan, Luxembourg, Macau, Mexico, Netherlands, New Zealand, Poland, Russia, Spain, South Africa, South Korea, Sweden, Switzerland, Thailand, Turkey, and Ukraine.</p>
				</div> */}

				<div className={IndexStyles.intro}>
					<h1 className={`h3`}>Welcome to our UK Warehouse website!</h1>
					<p className={`body-text ${ IndexStyles.spacer }`}>
						This site shows our equipment available immediately in our UK warehouse. To see Balanced Body&apos;s complete product line, please visit our
						{' '}
						<a href={'https://www.pilates.com'} target={'_blank'} style={styles.underline} rel={'noreferrer'}>US website</a>
						{' '}
						or

						<a href={'https://s3.amazonaws.com/s3.pilates.com/resources/2024-catalog/2024_catalog_87trfee6flnp.pdf'} target={'_blank'} style={styles.underline} rel={'noreferrer'}>download our 2024 catalog</a>
						.
					</p>
					<p className={`body-text ${ IndexStyles.spacer }`}>
						Contact us •
						{' '}
						<a href={'mailto:info@pilates.com?subject=UK Warehouse website query'} style={styles.underline}>info@pilates.com</a>
						{' '}
						or
						{' '}
						<a href={'tel:0800 014 8207'} style={styles.underline}>0800 014 8207</a>
						{' '}
						or
						{' '}
						<a href={'tel:0044 8000148207'} style={styles.underline}>0044 8000148207</a>
						{' '}
						(from Republic of Ireland)
					</p>
				</div>

				<TwoColTextContent items={[
					{
						title: 'Read All About Us!',
						copy: 'We’re honored to be featured for our acclaimed equipment and subject expertise in Pilates, fitness, women’s health, and physical rehabilitation.',
						link: '/press-room',
					},
					{
						title: 'Acclaimed Equipment',
						copy: 'With nearly 50 years in Pilates and fitness, our Reformers have proven unparalleled performance, durability and functionality.',
						link: '/reformers',
					},
				]}
				/>

				{/* <StaticImage
					className={IndexStyles.image}
					src={'../assets/images/uk-home@2x.jpg'}
					alt={'People working out on Reformers'}
				/> */}

				{/* <div className={IndexStyles.promocontainer}>
					<StaticImage
						className={IndexStyles.promoimage}
						src={'../assets/images/2024_pot_manchester_2@2x.jpg'}
						alt={'Pilates on Tour Manchester'}
					/>
					<div className={IndexStyles.promoblock}>
						<p className={`eyebrow-text`}>17 - 19 May, 2024</p>
						<h1 className={`h3`}>
							Pilates on Tour
							<span style={styles.small}>®</span>
							, Manchester
						</h1>
						<p className={`body-text ${ IndexStyles.promotext }`}>We are excited to bring Pilates On Tour to Manchester, 17-19 May.  This must-attend continuing education event features a roster of internationally-acclaimed Presenters, cutting-edge curriculum and lots of practice time on premium Balanced Body® equipment!</p>
						<p className={`body-text ${ IndexStyles.promotext }`}>
							<a className={`link-text`} href={'https://www.pilates.com/pilates-on-tour-manchester'} target={'_blank'} rel={'noreferrer'}>REGISTER TODAY</a>
							!
						</p>
						<p className={`body-text ${ IndexStyles.promotext }`}>
							Specially priced event demo equipment for sale -
							<a className={`link-text`} href={' https://www.pilates.com/equipment-for-sale-pot-manchester'} target={'_blank'} rel={'noreferrer'}>RESERVE NOW</a>
							!
						</p>
					</div>
				</div> */}
			</div>
		</Layout>
	);
};

export default Index;
